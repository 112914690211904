import React, { useEffect } from 'react'
import game1 from './Image/Red VS Black.png'
import game2 from './Image/Roulette.png'
import game3 from './Image/Dragon VS Tiger.webp'
import game4 from './Image/joker-min.webp'
import install from './Image/install.png'
import login from './Image/login.png'
import play from './Image/playGame.png'
import { RiFacebookBoxFill } from "react-icons/ri";
import { RiYoutubeFill } from "react-icons/ri";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import './Home.css'
import { Link } from 'react-router-dom';
import video1 from '../Components/Image/videoSlot.mp4'
import video2 from '../Components/Image/video3.mp4'

export const Home = () => {
    const handleDownload = () => {
        // Replace 'yourfile.apk' with the name of your APK file
        const apkUrl = process.env.PUBLIC_URL + '/TeenpattiPlatinum.apk';
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = apkUrl;
        link.download = 'TeenpattiPlatinum.apk'; // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className='homeMain1'>
            <div className='hoeMainCont'>
                <div className='homeMainChild text-center'>
                  <h2>Play Casino Games <br></br><span style={{ color: '#519f10' }}>Win Big
                        Today</span></h2>
                    <p style={{ lineHeight: '18px', fontSize: '14px' }}>Online casinos have become increasingly popular in <br></br> recent years offering players a
                        convenient and  <br></br> accessible way to enjoy their favorite casino games  <br></br> from anywhere in the world.</p>
                    <button style={{ background: '#519f10', border: 'none', color: 'white', fontSize: '12px', padding: '12px 25px' }} onClick={handleDownload} download>PLAY NOW</button>
                </div>
                {/* <div className='homeMainChild'>
                    <img src={casino} alt='' style={{ width: '100%', height: '30vh' }} />
                </div> */}
            </div>
            <div className='p-2 welcomeCont'>
                <div className='welcomeCont2 text-center'>
                    <h4 style={{color:'goldenrod'}}>Welcome in Teenpatti Platinum</h4>
                    <p style={{ fontSize: '12px',color:'white',padding:'2px'}}>One of the most significant benefits of online casinos is their
                        convenience. Players no longer have to travel to a
                        physical casino to enjoy their favorite games as they can access them from anywhere with an
                        internet connection. This
                        means that players can play at any time of the day or night, making it a perfect option for
                        those with busy schedules or
                        those who prefer to play in the comfort.</p>
                    <p style={{ fontSize: '12px',color:'white',padding:'2px'}}>Another benefit of online casinos is the wide range of games on offer.
                        From classic table
                        games like roulette and
                        blackjack to new and innovative slot titles, online casinos have something for everyone.
                        Additionally, many online
                        casinos offer live dealer games, which provide an immersive and interactive gaming
                        experience.</p>
                    <div style={{ width: '100%', height: '25vh' }}>
                    <video controls width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video2} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
                    </div>
                </div>
            </div>
            <div className='chooseYour'>
                <div style={{ width: '100%', height: '8vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{color:'goldenrod'}}>Choose Your Game and Play</h4>
                </div>
                <div className='chooseChild1'>
                      <div className='chooseChild2'>
                          <div style={{height:'25vh',width:'45%'}}>
                          <img src={game3} class="d-block w-100" alt="..." style={{ height: 'auto' }}/>
                          </div>
                          <div style={{height:'25vh',width:'45%'}}>
                          <img src={game2} class="d-block w-100" alt="..." />
                          </div>
                      </div>
                      <div className='chooseChild2'>
                          <div style={{height:'25vh',width:'45%'}}>
                          <img src={game1} class="d-block w-100" alt="..." style={{ height: 'auto' }} />
                          </div>
                          <div style={{height:'25vh',width:'45%'}}>
                          <img src={game4} class="d-block w-100" alt="..." />
                          </div>
                      </div>
                </div>
            </div>
            <div className='bigWIn'>
                <video controls width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video1} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
            </div>
            <div className='clientRev'>
                       <div style={{height: '53vh',width:'56%'}}>
                       <div style={{ width: "100%", height: '13vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', lineHeight: '0px' }}>
                            <h5 style={{ color: 'white' }}>960</h5>
                            <hr style={{ width: '20%',color:'gold',padding:'4px',margin:'4px' }}></hr>
                            <p style={{ fontSize: '14px',color:'gold' }}>Today Win</p>
                        </div>
                        <div style={{ width: "100%", height: '13vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', lineHeight: '0px' }}>
                            <h5 style={{ color: 'white' }}>297</h5>
                            <hr style={{ width: '20%',color:'gold',padding:'4px',margin:'4px'}}></hr>
                            <p style={{ fontSize: '14px',color:'gold' }}>Live Tournaments</p>
                        </div>
                        <div style={{ width: "100%", height: '13vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', lineHeight: '0px' }}>
                            <h5 style={{ color: 'white' }}>429</h5>
                            <hr style={{ width: '20%',color:'gold',padding:'4px',margin:'4px'}}></hr>
                            <p style={{ fontSize: '14px',color:'gold' }}>Online Players</p>
                        </div>
                        <div style={{ width: "100%", height: '13vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', lineHeight: '0px' }}>
                            <h5 style={{ color: 'white' }}>375</h5>
                            <hr style={{ width: '20%',color:'gold',padding:'4px',margin:'4px'}}></hr>
                            <p style={{ fontSize: '14px',color:'gold' }}>Happy Winners</p>
                        </div>
                       </div>
             </div>
            <div className='faq'>
                {/* <!-- FAQ 1 - Bootstrap Brain Component --> */}
                <section class="py-3 py-md-5">
                    <div class="container">
                        <div class="row gy-5 gy-lg-0 align-items-lg-center">
                            <div class="col-12">
                                <div class="row justify-content-xl-end">
                                    <div class="col-12 col-xl-11">
                                        <h3 class="mb-3" style={{ color: 'white' }}>Frequently Asked Questions</h3>
                                        <div class="accordion accordion-flush" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        What is a casino platform?
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p> Online casinos have become increasingly popular in recent years, offering players a convenient and accessible way enjoy their favorite casino games from anywhere in the world. These
                                                            platforms have revolutionized the gambling industry, providing a wide range of games and bonuses that were previously. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        What types of services does a casino platform offer?
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body"> Online casinos have become increasingly popular in recent years, offering players a convenient and accessible way to enjoy their favorite casino games from anywhere in the world. These platforms have revolutionized the gambling industry,providing a wide range of games and bonuses that were previously.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        How do casino platforms ensure fairness in their
                                                        games?
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Online casinos have become increasingly popular in recent years, offering players a convenient and accessible way to enjoy their favorite casino games from anywhere in the world. These platforms have revolutionized the gambling industry,providing a wide range of games and bonuses that were previously. </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What is the role of a casino platform provider?
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Online casinos have become increasingly popular in recent years, offering players a convenient and accessible way to enjoy their favorite casino games from anywhere in the world. These platforms have revolutionized the gambling industry,providing a wide range of games and bonuses that were previously. </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What is the cost of using a casino platform?
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Online casinos have become increasingly popular in recent years, offering players a convenient and accessible way to enjoy their favorite casino games from anywhere in the world. These platforms have revolutionized the gambling industry,providing a wide range of games and bonuses that were previously. </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
           
            <div style={{ background: '#412786', height: '45vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                <div style={{ width: '100%', height: '10vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h2 className='text-white'>Step To Start</h2>
                    <hr style={{ width: '50%', color: 'gold' }}></hr>
                </div>
                <div style={{ width: '90%', height: '25vh' }}>
                    <div id="carouselExampleAutoplayingI" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div className='card' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '25vh', border: 'none' }}>
                                    <img src={install} alt='' style={{ width: '20%', height: '8vh' }} />
                                    <h3>1.Install our app</h3>
                                    <button style={{ background: '#519f10', color: 'white', border: 'none', fontSize: '18px', padding: '8px' }} onClick={handleDownload} download>CLICK HERE</button>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div className='card' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '25vh', border: 'none' }}>
                                    <img src={login} alt='' style={{ width: '20%', height: '8vh' }} />
                                    <h3>2.Sign Up & Login
                                    </h3>
                                    <button style={{ background: '#519f10', color: 'white', border: 'none', fontSize: '18px', padding: '8px' }} onClick={handleDownload} download>CLICK HERE</button>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div className='card' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '25vh', border: 'none' }}>
                                    <img src={play} alt='' style={{ width: '20%', height: '8vh' }} />
                                    <h3>3.Choose Game & Play</h3>
                                    <button style={{ background: '#519f10', color: 'white', border: 'none', fontSize: '18px', padding: '8px' }} onClick={handleDownload} download>CLICK HERE</button>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplayingI" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplayingI" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
           </div>
           <div style={{ background: "#293047", height: '115vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                <div className='text-center' style={{ width: '90%', height: '6vh' }}>
                    <p style={{ color: 'red', fontSize: '15px' }}>*This Game is Only meant for the 18+age group.*</p>
                </div>
                <div style={{ width: '60%', height: '37vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h5 style={{ color: 'white' }}>SOCIAL LINKS</h5>
                    <hr style={{ width: '40%', color: '#63ff16' }}></hr>
                    <p style={{ color: 'white' }}><RiFacebookBoxFill style={{ fontSize: '25px', color: '#519f10' }} />Facebook</p>
                    <p style={{ color: 'white' }}><FaSquareInstagram style={{ fontSize: '25px', color: '#519f10' }} />Instagram</p>
                    <p style={{ color: 'white' }}><RiYoutubeFill style={{ fontSize: '25px', color: '#519f10' }} /> Youtube</p>
                    <p style={{ color: 'white' }}><FaTelegram style={{ fontSize: '25px', color: '#519f10' }} /> Telegram</p>

                </div>
                <div style={{ width: '60%', height: '29vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h5 style={{ color: 'white' }}>INFORMATION</h5>
                    <hr style={{ width: '40%', color: '#63ff16' }}></hr>
                    <Link to='/about' style={{ textDecoration: 'none' }}> <p style={{ color: 'white' }}>About Us</p> </Link>
                    <Link to='/pricing' style={{ textDecoration: 'none' }}> <p style={{ color: 'white' }}>Pricing</p> </Link>
                    <Link to='/cont' style={{ textDecoration: 'none' }}>   <p style={{ color: 'white' }}>Contact Us</p> </Link>
                </div>
                <div style={{ width: '60%', height: '30vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h5 style={{ color: 'white' }}>QUICK LINKS</h5>
                    <hr style={{ width: '40%', color: '#63ff16' }}></hr>
                    <Link to='/privacy' style={{ textDecoration: 'none' }}><p style={{ color: 'white' }}>Privacy Policy</p></Link>
                    <Link to='/terms' style={{ textDecoration: 'none' }}>  <p style={{ color: 'white' }}>Terms & Conditions</p> </Link>
                    <Link to='/cancell' style={{ textDecoration: 'none' }}>    <p style={{ color: 'white' }}>Cancellation and Refund Policy</p> </Link>
                </div>
                <hr style={{ width: '100%' }}></hr>
                <p style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>Copyright 2023 teenpattiplatinum.online All rights reserved</p>
            </div>
        </div>
    )
}
