import React from 'react';
import { HiOutlineListBullet } from "react-icons/hi2";
import '../Components/Navbar.css'
import { Link } from 'react-router-dom';
import logo from '../Components/Image/logo1.webp'
function navbar() {
  const handleDownload = () => {
    // Replace 'yourfile.apk' with the name of your APK file
    const apkUrl = process.env.PUBLIC_URL + '/TeenpattiPlatinum.apk';
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'TeenpattiPlatinum.apk'; // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <>
   <nav class="navbar fixed-top custom-navbar">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img src={logo} alt='' style={{width:'100%',height:'10vh'}}/></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation" style={{background:'none',border:'none'}}>
       <HiOutlineListBullet style={{color:'#519f10',fontSize:'40px'}}/>
    </button>
    <div class="offcanvas offcanvas-end custom-offcanvas" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Teenpatti Platinum</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to='/'>Home</Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to='/about'>About us</Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link active" aria-current="page" to='/cont'>Contact Us</Link>
          </li>
          <li class="nav-item">
            <button class="nav-link active" aria-current="page"   onClick={handleDownload} download>Download Apk</button>
          </li>
        </ul>
        
      </div>
    </div>
  </div>
   </nav> 
 
  
    </>
  );
}

export default navbar;