import React from 'react'
import './Components/Home.css'
import { Downloadbtn } from './Components/Downloadbtn';
import { Home } from './Components/Home';
import Navbar from './Components/Navbar';
export const Main = () => {
  return (
    <div className='MainCont'>
  <div style={{width:'450px'}}>

<Navbar/>
  
   <Home/>
     <div style={{position:'fixed',bottom:'15px'}}>
    <div>
    <Downloadbtn/>
    </div>
 
  </div>
    </div>
    </div>
  
 
  )
}
