import React from 'react'
import { MdOutlineCloudDownload } from "react-icons/md";

export const Downloadbtn = () => {
    const handleDownload = () => {
        // Replace 'yourfile.apk' with the name of your APK file
        const apkUrl = process.env.PUBLIC_URL + '/TeenpattiPlatinum.apk';
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = apkUrl;
        link.download = 'TeenpattiPlatinum.apk'; // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    
  return (
    <div>
    <button style={{backgroundColor:'red',border:'none',color:'white',fontSize:'20px',fontWeight:'bold',height:'50px',width:'100%',marginLeft:'10px'}} onClick={handleDownload} download>
   <MdOutlineCloudDownload style={{fontSize:'22px'}}/>Download Apk
   </button>
</div>
)
}
