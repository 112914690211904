
import { AllRoutes } from './Components/AllRoutes';
import { Main } from './Main';

function App() {
  return (
   <>
   <AllRoutes/>
   </>
  );
}

export default App;
